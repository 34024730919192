:root{
    --darkergray: #747474;
    --genColor:#E5E5E5;
    --black: #000000;
    --white: #ffffff;
    --darkgray: #909090;
    --gray: #C4C4C4;

    --transform: translateY(5px);
    --transition: all 400ms ease;
}
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    text-decoration: none;
}
body{
    background-color: var(--genColor);
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 3rem;
}

/* -------HOME PAGE------ */
.landing--pg{
    width: 100%;
    height: 90vh;
    overflow: hidden;
}
.name--sec{
    display: flex;
    justify-content: center;
    margin-top: 11rem;
}
.name{
    font-family: Tahoma, sans-serif, Verdana;
    font-size: 2.3rem;
}
.handwave{
    width: 30px;
    margin-left: 10px;
}
.dev-info{
    margin-top: 1rem;
    color: var(--darkergray);
    line-height: 20px;
}
.cv--div{
    margin-top: 1.5rem;
    background-color: var(--gray);
    width: 9rem;
    height: 3rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 5px var(--darkergray);
    transition: var(--transition)
}
.downloadIcon{
    padding: 5px;
}
.cv--div:hover{
    background-color: var(--black);
    color: var(--genColor);
    transform: var(--transform)
}
.user-pic--main{
    width: 250px;
	height: 250px;
    margin-left: 2.5rem;
   
}
.user--pic{
    background-image: linear-gradient(to bottom left, var(--darkgray), var(--genColor));
    width: 100%;
    height: 100%;
	overflow: hidden;
    border-radius:70px 0;
    /* transform: rotate(10deg);
    transition: var(--transition);
    position:relative; */
}
.user--pic:hover{
    transform: rotate(0deg);
}
.user--pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.socia--handles{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 3rem;
    bottom: 1rem;
}
.socia--handles::after{
    content: "";
    width: 2px;
    height: 3rem;
    background-color: var(--gray);
    margin-top: 10px;

}
.socia--handles--icon{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    border-radius: 2px;
    background-color: var(--gray);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    transition: var(--transition);
}
.socia--handles--icon:hover{
    background-color: var(--black);
    color: var(--genColor);
    transform: var(--transform)
}
.socia--handles--icon a{
    text-decoration: none;
    color: var(--black);
    transition: var(--transition);
}
.socia--handles--icon a:hover{
    color: var(--white);
}
/* ------NAVBAR PAGE ------ */

.nav--main{
    display: flex;
    justify-content:space-between;
    align-items: center;  
    padding-top: 1rem;
    /* background-color: var(--darkgray); */
}
.checkbtn{
    font-size: 24px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}
.navbar--nav{
    display: flex;
    justify-content: flex-end;
    width: 100%;
} 
.navlink{ 
    list-style: none;
    text-decoration: none;
    margin-left: 1rem;
    color: var(--black);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
}
.navlink:hover{
    color: var(--darkergray);
}



/* ------ERROR PAGE----- */
.error--page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}.error--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.error--image{
    width: 100px;
    height: 100px;
}
.txt--404{
    margin-top: 1rem;
    color: var(--darkergray);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
}
.error--msg{
    color: var(--darkergray);
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
}
.error--txt{
    color: var(--darkergray);
    font-size: 12px;
    margin-top: 1rem;
    line-height: 20px;
}

/* -----ABOUT PAGE----- */

.about--page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vh;
}
.abt--main--page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    /* justify-content: center; */
    align-items: center;
    
}
.head--txt{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 2rem;
    margin-top: 3rem;
}
.about--container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.about-image--div{
    width: 330px;
    height: 280px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 0px 7px var(--darkgray);
    margin-right: 5rem;
}
.abtImage{
    width: 100%;
    height: 100%;
}
.about--info{
    width: 330px;
    overflow: hidden;
    
}
.abtInfo--header{
    font-size: 26px;
}
.abtinfo--txt{
    margin-top: 1rem;
    font-size: 14px;
}
.about--card--main{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
    gap: 3rem;
}

.about--card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: auto;
    padding: 1.5rem;
    background-color: var(--gray);
    border-radius: 10px;
    transition: var(--transition);
}
.about--card:hover{
    background: transparent;
    border: 1px solid var(--darkgray);
}
.ab--card--head{
    margin-top: 0.5rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.ab--card--txt{
    margin-top: 0.5rem;
    font-size: 14px;
}


/* ------SKILL PAGE------ */

.skill-head-txt{
    margin-top: 3rem;   
}
.skill--page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.now--using--txt{
    margin-top: 2rem;
}
.now--using{
    display: grid;
    width: auto;
    height: auto;
    grid-gap: 24px;
    padding: 20px;
    justify-content: center;
    
    /* border: 1px solid orangered; */
    @media (min-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
    }
    @media (min-width: 800px) {
        grid-template-columns: repeat(4, 1fr);
        width: 700px;
    }
}
.skill--div{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 0px 7px var(--darkergray); */
}
.skill--el--img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
	height: 60px;
	overflow: hidden;
    border-radius: 35px;
    box-shadow: 0px 0px 7px var(--darkergray);
    transition: var(--transition);
    transform: rotate(-20deg);
}
.skill--el--img:hover{
    background-color: var(--white);
    transform: rotate(360deg);
}
.skimg{
    width: 60px;
    height: 60px;
    padding: 0.7rem;
    object-fit: cover;
}
.skill--el{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 12px;
}
.learning-div{
    padding-bottom: 3rem;
}
.learning--txt{
    margin-top: 3rem;
}

/* -----PORTFOLIO PAGE----- */
.portfolio--page{
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comingSoon{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



/* -----CONTACT PAGE----- */

.contact--page{
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact--main{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 6rem;
    margin-top: 2rem;

    /*width: 100%;
    display: grid;
    grid-template-columns: 32% 58%;
     gap: 12%; 
    justify-content: center;*/
}
.contact--card--main{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 15rem;
}
.contact--card{
    background-color: var(--gray);
    font-size: 13px;
    text-align: center;
    padding: 1.2rem;
    border: 1px solid transparent;
    border-radius: 1.2rem;
    transition: var(--transition);
}
.contact--card:hover{
    background: transparent;
    border-color: var(--darkergray);
}
.contact--icon{
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
}
.contact--card a{
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input,textarea{
    width: 20rem;
    border-radius: 0.5rem;
    padding: 1.2rem;
    background: transparent;
    border: 2px solid var(--darkergray);
    resize: none;
    margin-top: 1rem;
}
.submit--btn{
    border: none;
    border: 1px solid transparent;
}
.submit--btn:hover{
    background: transparent;
    border-color: var(--darkergray);
    color: var(--black);
}





@media screen and (max-width: 700px) {
/* ------HOME PAGE ------ */
    .landing--pg{
        overflow:visible;
    }
    .name--sec{
        margin-top: 5rem;
        flex-direction: column;
        align-items: center;
    }
    .user-pic--main{
        margin: 3rem 0 3rem 0;
        min-width: 300px;
    }
    .socia--handles{
        display: none;
    }

    /* ------NAVBAR PAGE----- */

    .logo--img{
        position: absolute;
        top: 16px;
        left: 25px;
    }
    .checkbtn{
        color: var(--black);
        display: block;
        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 10;
    }
    .nav--main{
        display: block;
    }
    .navbar--nav{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        margin:0;
        top: -120%;
        left: 0;
        transition: var(--transition);
        width: 100%;
        height: 100vh;
        background: var(--gray);
    }
    #check:checked ~ ul{
        top:0px;
    }
    .navlink{
        margin-top: 50px;
    }


    /* ------ABOUT PAGE----- */
    .about--container{
        flex-direction: column;
        width: 100%;
        /* align-items: center; */
    }
    .about-image--div{
        margin-right: 0px;
        width: auto;
        height: auto;
    }
    .about--card--main{
        display: block;
        padding-bottom: 5rem;
        /* margin-bottom: 5rem; */

    }
    .about--card{
        margin-top: 1rem;
        width: auto;
    }
    .about--info{
        width: auto;
        margin-top: 2rem;
        /* margin-bottom: 3rem; */
    }
    .contact--main{
        display: block;
        align-items: center;
    }
    .contact--card{
        width: 20rem;
    }form{
        margin: 2rem 0px;
    }
}